import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function HorizontalRule({ className, invert }) {
  const black = invert ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        overflow: hidden;
      `}
    >
      <hr
        css={css`
          width: 100%;
          border: 0;
          border-bottom: 4px solid ${black};
          margin: 0;

          @media (max-width: 550px) {
            border-bottom-width: 3px;
          }
        `}
      />
    </div>
  );
}
