import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import BlockContent from '../components/block-content';

const AccordionItem = ({ title, children, isOpen, onClick, invert }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div
      css={css`
        border-bottom: 3px solid ${invert ? 'var(--white)' : 'var(--black)'};
      `}
    >
      <div
        onClick={onClick}
        css={css`
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          padding: 2.4rem 0;

          @media (max-width: 800px) {
            padding: 1.6rem 0;
          }

          &:hover {
            svg {
              circle {
                fill: var(--white);
              }

              path {
                fill: var(--black);
              }
            }
          }
        `}
      >
        {title}
        <span
          css={css`
            position: relative;
            top: 0.6rem;
            width: 5rem;
            height: 5rem;

            @media (max-width: 1600px) {
              top: 0;
              width: 4.6rem;
              height: 4.6rem;
            }

            @media (max-width: 800px) {
              width: 3.5rem;
              height: 3.5rem;
            }

            @media (max-width: 550px) {
              width: 2.5rem;
              height: 2.5rem;
            }
          `}
        >
          <svg
            css={css`
              transform: rotate(${isOpen ? '45' : '0'}deg);
              transition: 0.6s transform var(--curve);
              width: 5rem;
              height: 5rem;

              @media (max-width: 1600px) {
                width: 4.6rem;
                height: 4.6rem;
              }

              @media (max-width: 800px) {
                width: 3.5rem;
                height: 3.5rem;
              }

              @media (max-width: 550px) {
                width: 2.5rem;
                height: 2.5rem;
              }
            `}
            width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="20.5" fill="transparent" stroke="var(--white)" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 12H21V20L13 20V23H21V31H24V23H32V20L24 20V12Z" fill="var(--white)"/>
          </svg>
        </span>
      </div>
      <div
        css={css`
          overflow: hidden;
          height: ${height};
          transition: height 0.6s var(--curve);
          will-change: height;
        `}
        ref={contentRef}
      >
        <div
          css={css`
            padding: 1.4rem 0 2.4rem;

            @media (max-width: 800px) {
              padding: 0.7rem 0 1.6rem 0;
            }
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default function Accordion({ className, items, invert }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      className={`type--heading-four ${className}`}
      css={css`
        background-color: ${invert ? 'var(--black)' : 'var(--white)'};
        color: ${invert ? 'var(--white)' : 'var(--black)'};
        width: 100%;
        margin: 0 auto;
        border-top: 3px solid ${invert ? 'var(--white)' : 'var(--black)'};
      `}
    >
      {items.length > 0 && items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isOpen={openIndex === index}
          onClick={() => toggleAccordion(index)}
          invert={invert}
        >
          <BlockContent content={item._rawText} />
        </AccordionItem>
      ))}
    </div>
  );
}
