import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Box from '../components/box';
import HorizontalRule from '../components/horizontal-rule';
import InlineGallery from '../components/inline-gallery';
import Accordion from '../components/accordion';
import Pill from '../components/pill';
import LinkPanel from '../components/link-panel';
import Grid from '../components/grid';
import StudioForm from '../components/studio-form';

export default function Studio({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(true);
    theme.setBackURL('/');
  }, []);

  return (
    <div
      css={css`
        color: var(--white);
        background-color: var(--black);

        a,
        a:visited {
          color: var(--white);
        }
      `}
    >
      <SEO
        title="Studio"
        description={
          data.studio._rawIntroText
            ? data.studio._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
      />
      <div>
        {data.studio.introMedia.length > 0 && <InlineGallery
          items={data.studio.introMedia.map((item) => {
            if (item.type === 0) {
              return {
                assetType: 'image',
                image: item.image,
              };
            } else if (item.type === 1) {
              return {
                assetType: 'video',
                src: item.video.asset.url,
              };
            } else {
              return null;
            }
          })}
        />}
        {(data.studio._rawIntroText) &&
          <Box x={10} y={10}>
            <div
              className="type--heading-three"
              css={css`
                width: 100%;
                color: var(--white);
              `}
            >
              <BlockContent content={data.studio._rawIntroText} />
            </div>
          </Box>
        }
        {data.studio.accordion.items.length > 0 && 
          <Box x={10} y={16}>
            <div
              className="type--heading-three"
              css={css`
                width: 100%;
                color: var(--white);
              `}
            >
              <Accordion items={data.studio.accordion.items} invert />
            </div>
          </Box>
        }
        {(data.studio._rawStudiosText) &&
          <Box x={10} y={10}>
            <div
              className="type--heading-three"
              css={css`
                width: 100%;
                color: var(--white);
              `}
            >
              <BlockContent content={data.studio._rawStudiosText} />
            </div>
          </Box>
        }
        {data.studio.studios.length > 0 && 
          <Box x={10} y={18}>
            <div
              className="type--heading-five"
              css={css`
                width: 100%;
                color: var(--white);
              `}
            >

              {data.studio.studios.map((item, index) => (
                <div
                  key={index}
                  css={css`
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: var(--gutter);
                    margin-bottom: var(--gutter);

                    @media (max-width: 800px) {
                      display: block;
                      margin-bottom: var(--margin6);
                    }
                  `}
                >
                  <div
                    css={css`
                      @media (max-width: 800px) {
                        margin-bottom: var(--gutter);
                      }
                    `}
                  >
                    <div>{item.title}</div>
                    <div
                      css={css`
                        margin-bottom: 1.2em;

                        p {
                          margin-bottom: 0.5em;
                        }
                      `}
                    >
                      <BlockContent content={item._rawText} />
                    </div>
                    <Pill
                      text={item.buttonText}
                      invert
                      size={2}
                      url={item.url ? item.url : null}
                    />
                  </div>
                  <div
                    css={css`
                      img {
                        width: 100%;
                      }
                    `}
                  >
                    <SanityImage width={990} height={1152} {...item.image} />
                  </div>
                </div>
              ))}
            </div>
          </Box>
        }
        <div>
          <HorizontalRule invert={true} />
          <StudioForm 
            css={css`
              padding-bottom: var(--margin14);
            `}
            invert
          />
        </div>
        {(data.studio._rawRegistrationText) &&
          <>
            <HorizontalRule invert={true} />
            <Box x={10} y={13}>
              <Grid
                columns={10}
              >
                <div
                  className="type--paragraph"
                  css={css`
                    width: 100%;
                    color: var(--white);
                  `}
                >
                  <BlockContent content={data.studio._rawRegistrationText} />
                </div>
              </Grid>
            </Box>
          </>
        }
      </div>
      <LinkPanel
        hoverColor="var(--black)"
        hoverBackground="var(--white)"
        invert={true}
        content={[
          {
            text: 'Work',
            url: '/work',
          },
          {
            text: 'People',
            url: '/people',
          },
        ]}
      />
    </div>
  );
}

export const query = graphql`
  query {
    studio: sanityStudio2024(_id: { regex: "/(drafts\\\\.studio-2024)?/" }) {
      introMedia {
        type
        image {
          ...Image
          asset {
            description
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
        video {
          asset {
            url
          }
        }
      }
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      accordion {
        items {
          _rawText(resolveReferences: {maxDepth: 10})
          title
        }
      }
      studios {
        _rawText
        buttonText
        title
        url
        image {
          ...Image
          asset {
            description
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
      }
      _rawStudiosText(resolveReferences: { maxDepth: 10 })
      _rawRegistrationText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
